var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return false ? _c('v-sheet', {
    staticClass: "my-4 py-2"
  }, [_c('v-slide-group', {
    attrs: {
      "mandatory": "",
      "show-arrows": "",
      "center-active": ""
    },
    on: {
      "change": function change($event) {
        return _vm.onClick();
      }
    },
    model: {
      value: _vm.group,
      callback: function callback($$v) {
        _vm.group = $$v;
      },
      expression: "group"
    }
  }, _vm._l(_vm.history, function (item, index) {
    return _c('v-slide-item', {
      key: index,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var active = _ref.active,
              toggle = _ref.toggle;
          return [_c('vx-btn', {
            staticClass: "mx-2",
            attrs: {
              "input-value": active,
              "active-class": "primary white--text",
              "depressed": "",
              "rounded": ""
            },
            on: {
              "click": toggle
            }
          }, [_vm._v(" " + _vm._s(item.name) + " ")])];
        }
      }], null, true)
    });
  }), 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }