var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p-navigation-drawer'), _c('div', {
    staticClass: "app-bar-bg"
  }), _c('p-app-bar'), _c('v-main', {
    staticClass: "ma-lg-6 mt-4"
  }, [_c('v-container', {
    attrs: {
      "fluid": !_vm.$vuetify.breakpoint.xl
    }
  }, [_c('p-breadcrumbs'), _c('router-view')], 1)], 1), _c('p-footer')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }