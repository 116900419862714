var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticClass: "mx-3 mx-lg-8 mt-4",
    attrs: {
      "app": "",
      "rounded": "",
      "elevation": 1,
      "color": "primary"
    }
  }, [_c('v-app-bar-nav-icon', {
    attrs: {
      "color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('admin-drawer');
      }
    }
  }), _c('b', {
    staticClass: "text-overflow w-50",
    attrs: {
      "title": _vm.title || _vm.$route.meta.title
    }
  }, [_vm._v(" " + _vm._s(_vm.title || _vm.$route.meta.title))]), _c('v-spacer'), _vm.$user ? _c('div', [_c('v-list-item', {
    staticClass: "text-right pa-0",
    attrs: {
      "dense": ""
    }
  }, [!_vm.$route.meta.hideUser ? _c('v-list-item-content', {
    staticClass: "hidden-xs-only"
  }, [_c('v-list-item-title', {
    staticClass: "font-bold"
  }, [_vm._v(" " + _vm._s(_vm.$user.displayName) + " ")]), _c('v-list-item-subtitle', {
    staticClass: "light--text"
  }, [_vm._v(" " + _vm._s(_vm.$user.email) + " ")])], 1) : _vm._e(), _c('v-menu', {
    attrs: {
      "offset-y": "",
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-list-item-avatar', _vm._g(_vm._b({
          staticClass: "border-2",
          attrs: {
            "rounded": ""
          }
        }, 'v-list-item-avatar', attrs, false), on), [_vm.$userHasImage() ? _c('vx-img', {
          attrs: {
            "src": _vm.$getUserImage(_vm.$user),
            "alt": "John"
          }
        }) : _c('span', {
          staticClass: "text-h5"
        }, [_vm._v(_vm._s(_vm._f("initial")(_vm.$user)))])], 1)];
      }
    }], null, false, 1252062265)
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('app-drawer');
      }
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-4"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-cog-outline")])], 1), _c('v-list-item-title', [_vm._v("Settings")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$logout();
      }
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-4"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-logout")])], 1), _c('v-list-item-title', [_vm._v("Logout")])], 1)], 1)], 1)], 1)], 1) : _c('div', [_c('v-list-item', {
    staticClass: "text-right pa-0",
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$logout();
      }
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-4"
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v("mdi-logout")])], 1), _c('v-list-item-title', [_vm._v("Logout")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }