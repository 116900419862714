<template>
  <!-- <v-sheet v-if="$route.meta && $route.meta.breadcrumbs && $route.meta.breadcrumbs.length" class="d-flex my-4">
    <div class="text-h6">{{ $route.meta.title }}</div>
    <v-divider class="mx-4" vertical />
    <v-icon>mdi-home-outline</v-icon>
    <div class=" my-auto breadcrumbs-divider">>></div>

    <v-breadcrumbs class="py-0 px-0" :items="$route.meta.breadcrumbs" divider=">>"></v-breadcrumbs>
  </v-sheet> -->

  <v-sheet v-if="false" class="my-4 py-2">
    <v-slide-group v-model="group" mandatory show-arrows center-active @change="onClick()">
      <v-slide-item v-for="(item, index) in history" :key="index" v-slot="{ active, toggle }">
        <vx-btn class="mx-2" :input-value="active" active-class="primary white--text" depressed rounded @click="toggle">
          {{ item.name }}
        </vx-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      group: 0,
      history: [],
    };
  },
  mounted() {
    this.$bus.$on('breadcrumb', history => {
      // this.key++;
      this.history = history;
      this.group = this.history.length - 1;
    });
  },
  methods: {
    onClick() {
      const route = this.history[this.group];
      this.$router.push({ name: route.name, params: { skip: true } });
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs-divider {
  color: rgba(0, 0, 0, 0.38);
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  padding: 0 12px;
}
</style>
