<template>
  <v-app-bar app rounded :elevation="1" color="primary" class="mx-3 mx-lg-8 mt-4">
    <v-app-bar-nav-icon color="white" @click="$root.$emit('admin-drawer')"></v-app-bar-nav-icon>

    <b :title="title || $route.meta.title" class="text-overflow w-50"> {{ title || $route.meta.title }}</b>

    <v-spacer />

    <div v-if="$user">
      <v-list-item dense class="text-right pa-0">
        <v-list-item-content v-if="!$route.meta.hideUser" class="hidden-xs-only">
          <v-list-item-title class="font-bold">
            {{ $user.displayName }}
          </v-list-item-title>

          <v-list-item-subtitle class="light--text">
            {{ $user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-menu offset-y min-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-avatar v-bind="attrs" rounded class="border-2" v-on="on">
              <vx-img v-if="$userHasImage()" :src="$getUserImage($user)" alt="John" />
              <span v-else class="text-h5">{{ $user | initial }}</span>
            </v-list-item-avatar>
          </template>

          <v-list dense>
            <v-list-item @click="$root.$emit('app-drawer')">
              <v-list-item-icon class="mr-4">
                <v-icon color="primary">mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>

            <v-list-item @click="$logout()">
              <v-list-item-icon class="mr-4">
                <v-icon color="primary">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </div>
    <div v-else>
      <v-list-item dense class="text-right pa-0" @click="$logout()">
        <v-list-item-icon class="mr-4">
          <v-icon color="white">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      title: null,
    };
  },
  mounted() {
    this.$bus.$on('app-bar-title', title => {
      this.title = title;
    });
  },
};
</script>
