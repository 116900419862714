<template>
  <div>
    <p-navigation-drawer />
    <div class="app-bar-bg"></div>
    <p-app-bar />

    <v-main class="ma-lg-6 mt-4">
      <v-container :fluid="!$vuetify.breakpoint.xl">
        <p-breadcrumbs />

        <router-view></router-view>
      </v-container>
    </v-main>

    <p-footer />
  </div>
</template>

<script>
import pAppBar from './components/app-bar.vue';
import pFooter from './components/footer.vue';
import pBreadcrumbs from './components/breadcrumbs.vue';
import pNavigationDrawer from './components/navigation-drawer.vue';

export default {
  name: 'AdminLayout',
  components: {
    pAppBar,
    pFooter,
    pBreadcrumbs,
    pNavigationDrawer,
  },
};
</script>
<style scoped>
.app-bar-bg {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--v-secondary-base);
  height: 78px;
  padding-top: 2.2rem;
  z-index: 1;
}
</style>
