var render = function () {
  var _vm$$vuetify$theme, _vm$$vuetify$theme$th, _vm$$vuetify$theme$th2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "app": ""
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-title', {
    staticClass: "pa-4"
  }, [_c('Logo', {
    attrs: {
      "color": (_vm$$vuetify$theme = _vm.$vuetify.theme) === null || _vm$$vuetify$theme === void 0 ? void 0 : (_vm$$vuetify$theme$th = _vm$$vuetify$theme.themes) === null || _vm$$vuetify$theme$th === void 0 ? void 0 : (_vm$$vuetify$theme$th2 = _vm$$vuetify$theme$th.dark) === null || _vm$$vuetify$theme$th2 === void 0 ? void 0 : _vm$$vuetify$theme$th2.primary
    }
  })], 1), _c('v-spacer'), _c('v-list-item-icon', {
    staticClass: "ma-auto",
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('admin-drawer');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-menu")])], 1)], 1), _c('v-divider'), _c('v-list', {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [item.items ? _c('v-list-group', {
      key: index,
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-content', [_c('v-list-item-title', {
            domProps: {
              "textContent": _vm._s(item.text)
            }
          })], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }, [_c('v-draggable', {
      attrs: {
        "handle": ".handle",
        "ghost-class": "ghost"
      },
      model: {
        value: item.items,
        callback: function callback($$v) {
          _vm.$set(item, "items", $$v);
        },
        expression: "item.items"
      }
    }, _vm._l(item.items, function (child) {
      return _c('v-list-item', {
        key: child.text,
        staticClass: "handle cursor-move v-list-item-icon-center",
        class: {
          'primary v-list-item--active': _vm.isActive(child)
        },
        attrs: {
          "link": "",
          "exact": "",
          "to": {
            name: child.value,
            path: child.path
          }
        }
      }, [_c('v-list-item-icon', [_c('v-icon', {
        attrs: {
          "color": "primary"
        }
      }, [_vm._v(_vm._s(child.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
        domProps: {
          "textContent": _vm._s(child.text)
        }
      })], 1)], 1);
    }), 1)], 1) : _c('v-list-item', {
      key: index,
      staticClass: "v-list-item-icon-center",
      class: {
        'primary v-list-item--active': _vm.isActive(item)
      },
      attrs: {
        "link": "",
        "exact-path": "",
        "to": {
          name: item.value,
          path: item.path
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.text))])], 1)], 1)];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }