<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-list-item>
      <v-list-item-title class="pa-4">
        <Logo :color="$vuetify.theme?.themes?.dark?.primary" />
      </v-list-item-title>

      <v-spacer />

      <v-list-item-icon class="ma-auto" @click="$root.$emit('admin-drawer')">
        <v-icon color="primary">mdi-menu</v-icon>
      </v-list-item-icon>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav dense>
      <template v-for="(item, index) in items">
        <v-list-group v-if="item.items" :key="index" v-model="item.value">
          <template v-slot:activator>
            <v-list-item-content> <v-list-item-title v-text="item.text"></v-list-item-title> </v-list-item-content>
          </template>

          <v-draggable v-model="item.items" handle=".handle" ghost-class="ghost">
            <v-list-item
              v-for="child in item.items"
              :key="child.text"
              class="handle cursor-move v-list-item-icon-center"
              :class="{ 'primary v-list-item--active': isActive(child) }"
              link
              exact
              :to="{ name: child.value, path: child.path }"
            >
              <v-list-item-icon>
                <v-icon color="primary">{{ child.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="child.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-draggable>
        </v-list-group>

        <v-list-item
          v-else
          :key="index"
          class="v-list-item-icon-center"
          :class="{ 'primary v-list-item--active': isActive(item) }"
          link
          exact-path
          :to="{ name: item.value, path: item.path }"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import vDraggable from 'vuedraggable';
import _ from 'lodash';

import { Logo } from '@tutti/components';
import { CollectionService } from '@tutti/services';
import { NAVIGATIONS } from '@tutti/constants';

export default {
  components: { Logo, vDraggable },

  data() {
    return {
      key: 0,
      items: [],
      shortcuts: [],
      navigations: _.cloneDeep(NAVIGATIONS),
      drawer: this.$vuetify.breakpoint.lgAndUp,
    };
  },

  watch: {
    '$route.name'() {
      this.setValue();
    },
  },
  async mounted() {
    await this.getShortcuts();
    this.$root.$on('admin-drawer', () => {
      this.drawer = !this.drawer;
    });
    this.setItems();
    this.setValue();
  },

  methods: {
    setItems() {
      this.items = _.reduce(
        this.navigations,
        (acc, nav) => {
          if (nav.key === 'shortcut') {
            nav.items = this.shortcuts?.map(shortcut => {
              return {
                key: 'shortcut',
                text: shortcut.name,
                icon: shortcut.fields?.icon,
                path: shortcut.fields?.path,
              };
            });
          }
          if (nav.items) {
            nav.items = nav.items.filter(item => this.$user?.permissions.includes(item.key));
            if (nav.items.length) {
              acc.push(nav);
            }
          } else if (this.$user?.permissions.includes(nav.key)) {
            acc.push(nav);
          }
          return acc;
        },
        []
      );
    },
    setValue() {
      _.forEach(this.items, item => {
        if (item.items && item.items.some(_item => this.isActive(_item))) {
          item.value = true;
          return false;
        }
      });
    },
    isActive(route) {
      if (route.path) {
        return route.path === this.$route.fullPath;
      } else {
        return route.value === this.$route.name || route.value === this.$route?.meta?.list;
      }
    },
    async getShortcuts() {
      const response = await CollectionService.get({ filter: { schemaId: 'shortcuts' } });
      if (response) {
        this.shortcuts = response.data?.data || [];
      }
    },
  },
};
</script>
